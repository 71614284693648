function setImgUrl(item) {
  let img_url = "";
  if (process.env.NODE_ENV === "development") {
    img_url = "http://localhost:3002/" + item.img_path.split("/").pop();
  } else {
    img_url =
      "https://padaw3n-journal.herokuapp.com/" + item.img_path.split("/").pop();
  }
  return img_url;
}

export default setImgUrl;
