import React, { Fragment } from "react";
import Header from "../../../components/header/Header";
import { articleService } from "../../../app/services/articles.service";
import LoadingView from "../../../views/loading/LoadingView";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import "./createArticle.css";
import toast, { Toaster } from "react-hot-toast";
import { adminService } from "../../../app/services/admin.service";
import { useNavigate } from "react-router-dom";
import Parser from "html-react-parser";

class CreateArticle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      inlineLoading: false,
      formData: {},
      articleContent: "",
      title: "",
      intro: "",
      writedBy: "",
      image: null,
      input_tags: [],
      all_tags: [
        { label: "NFT", value: "NFT" },
        { label: "Defi", value: "Defi" },
        { label: "Crypto", value: "Crypto" },
      ],
      disabled: true,
      selectedImg: null,
    };
    this.createArticle = this.createArticle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeIntro = this.handleChangeIntro.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleChangeWritedBy = this.handleChangeWritedBy.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.checkToken = this.checkToken.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
  }

  componentDidMount() {
    this.checkToken();
  }

  checkToken() {
    const formData = localStorage.getItem("user");
    adminService.get.checkToken(
      formData,
      (response) => {
        this.setState({
          isLoaded: true,
        });
      },
      (error) => {
        this.props.navigate("/");
      }
    );
  }

  onChange(e) {
    this.setState({ articleContent: e.target.getContent() });
  }

  handleChangeName(event) {
    let newFormData = Object.assign({}, this.state.formData);
    newFormData.title = event.target.value;
    this.setState({
      title: newFormData.title,
    });
  }

  handleChangeIntro(event) {
    let newFormData = Object.assign({}, this.state.formData);
    newFormData.intro = event.target.value;
    this.setState({
      intro: newFormData.intro,
    });
  }

  onFileChange(e) {
    // Update the state
    this.setState({ selectedImg: e.target.files[0] });
  }

  onImageChange(event) {
    if (!event.target.files?.length) {
      return;
    }
    this.setState({
      image: event.target.files[0],
    });
  }

  handleChangeWritedBy(event) {
    // Update the state
    this.setState({ writedBy: event.target.value });
  }

  onChangeInput(event) {
    this.setState({
      input_tags: event,
    });
  }

  createArticle(e) {
    e.preventDefault();
    const formDataTmp = {
      articleContent: this.state.articleContent,
      title: this.state.title,
      intro: this.state.intro,
      writedBy: this.state.writedBy,
      tags: JSON.stringify(this.state.input_tags),
    };

    const formData = new FormData();
    formData.append("mainPicture", this.state.image);
    for (const [key, value] of Object.entries(formDataTmp)) {
      formData.append(key, value);
    }
    articleService.post.createArticle(
      formData,
      (response) => {
        this.setState({
          articleContent: "",
          title: "",
          intro: "",
          writedBy: "",
          tags: "",
          selectedImg: null,
        });
        toast.success("L'article à bien été crée");
      },
      (error) => {
        this.setState({ isLoaded: true });
      }
    );
  }

  render() {
    let disabled = true;
    if (this.state.isLoaded) {
      if (
        this.state.articleContent !== "" &&
        this.state.title !== "" &&
        this.state.intro !== "" &&
        this.state.writedBy !== "" &&
        this.state.tags !== [] &&
        this.state.image !== null
      ) {
        disabled = false;
      }
      return (
        <Fragment>
          <Toaster />
          <div className="central-content-1200 padding-top-40 content">
            <form className="form-input" enctype="multipart/form-data">
              <label className="padding-bottom-5 display-block font-inter-600 color-969696 font-size-18">
                Titre article
              </label>
              <input
                placeholder="Enter le titre de l'article.."
                value={this.state.title}
                onChange={this.handleChangeName}
                className="input-wallet"
              />
              <label className="padding-bottom-5 display-block font-inter-600 color-969696 font-size-18">
                Intro article
              </label>
              <input
                placeholder="Enter l'intro"
                value={this.state.intro}
                onChange={this.handleChangeIntro}
                className="input-wallet"
              />
              <div>
                <label className="padding-bottom-5 display-block font-inter-600 color-969696 font-size-18">
                  Selectionner image principal
                </label>
                <input
                  className="padding-bottom-20"
                  type="file"
                  accept="image/*"
                  name="mainPictures"
                  onChange={this.onImageChange}
                />
              </div>
              <img src={this.state.image} alt="" />
              <label className="padding-bottom-5 display-block font-inter-600 color-969696 font-size-18">
                Ecrit par
              </label>
              <input
                placeholder="Enter le titre de l'article.."
                value={this.state.writedBy}
                onChange={this.handleChangeWritedBy}
                className="input-wallet"
              />
              <div className="select-input">
                <Select
                  onChange={this.onChangeInput}
                  defaultValue={[]}
                  isMulti
                  name="colors"
                  options={this.state.all_tags}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
              <div className="editor">
                <Editor
                  initialValue="<p>Et zéééé partiiii !!!!!!</p>"
                  init={{
                    height: "700",
                    width: "1000",
                    plugins: "link image code",
                    toolbar:
                      "undo redo | bold italic | alignleft aligncenter alignright | code",
                  }}
                  onChange={this.onChange}
                />
              </div>
              <button
                className="create-btn"
                disabled={disabled}
                onClick={(e) => this.createArticle(e)}
              >
                save
              </button>
            </form>
          </div>

          {/* PREVIEW ARTICLE */}
          <div className="preview-article">
            <div className="main-content-article">
              <div className="column-left">
                <div className="padding-20"></div>
              </div>
              <div className="article-body">
                <div
                  className={`content-text ${
                    this.state.input_tags.length > 0
                      ? this.state.input_tags[0].value
                      : ""
                  }`}
                >
                  <div className="padding-20">
                    {Parser(this.state.articleContent)}
                  </div>
                </div>
              </div>
            </div>
            <div className="main-content-article">
              <div className="column-left">
                <div className="padding-20"></div>
              </div>
              <div className="writed-by-section-big">
                <img
                  className="img-writed-by-big"
                  src="https://i.seadn.io/gae/asKy65v3v5g_xeJB7cnZfKrXet3Jm6Qj3lOtAGveO_vBIl-yGbJAZ1NE6InOGZjy60Efnc0qsxt__-eqNchhA5c8txLJ0a9103KoCQ?auto=format&w=1000"
                  alt="img"
                />
                <div className="text-writed-by">
                  <div className="writed-by-title-big">By Padaw3n</div>
                  <div className="writed-by-descr-big">
                    Enthousiaste depuis toujours pour les nouvelles
                    technologies, je me suis progressivement rapproché des
                    activités liées au Web3 lors de l'année 2021. Avec le temps,{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return <LoadingView title={"journal crypto"} />;
    }
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <CreateArticle {...props} navigate={navigate} />;
}

export default WithNavigate;
