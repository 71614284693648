import { globalConstants } from "../constants";
import HttpHelper from "../helpers/http.helper";
import axios from "axios";

async function getArticles(onSuccess, onError) {
  const requestOptions = {
    method: "GET",
    headers: HttpHelper.authHeader(),
  };

  let base_url = "";
  if (process.env.NODE_ENV === "development") {
    base_url = "http://localhost:3002";
  } else {
    base_url = "https://padaw3n-journal.herokuapp.com";
  }

  return fetch(base_url + "/api/v1/get-articles/", requestOptions)
    .then(HttpHelper.handleLoggedResponse)
    .then(
      (data) => {
        if (onSuccess) {
          onSuccess(data);
        }
        return data;
      },
      (error) => {
        if (onError) {
          onError(error);
        }
      }
    )
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
}

async function getLastArticles(onSuccess, onError) {
  const requestOptions = {
    method: "GET",
    headers: HttpHelper.authHeader(),
  };

  let base_url = "";
  if (process.env.NODE_ENV === "development") {
    base_url = "http://localhost:3002";
  } else {
    base_url = "https://padaw3n-journal.herokuapp.com";
  }

  return fetch(base_url + "/api/v1/get-last-articles/", requestOptions)
    .then(HttpHelper.handleLoggedResponse)
    .then(
      (data) => {
        if (onSuccess) {
          onSuccess(data);
        }
        return data;
      },
      (error) => {
        if (onError) {
          onError(error);
        }
      }
    )
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
}

async function getArticle(id, onSuccess, onError) {
  const requestOptions = {
    method: "GET",
    headers: HttpHelper.authHeader(),
  };

  let base_url = "";
  if (process.env.NODE_ENV === "development") {
    base_url = "http://localhost:3002";
  } else {
    base_url = "https://padaw3n-journal.herokuapp.com";
  }

  return fetch(base_url + "/api/v1/article/" + id, requestOptions)
    .then(HttpHelper.handleLoggedResponse)
    .then(
      (data) => {
        if (onSuccess) {
          onSuccess(data);
        }
        return data;
      },
      (error) => {
        if (onError) {
          onError(error);
        }
      }
    )
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
}

async function readMoreArticles(tags, onSuccess, onError) {
  const requestOptions = {
    method: "GET",
    headers: HttpHelper.authHeader(),
  };

  let base_url = "";
  if (process.env.NODE_ENV === "development") {
    base_url = "http://localhost:3002";
  } else {
    base_url = "https://padaw3n-journal.herokuapp.com";
  }

  return fetch(base_url + "/api/v1/read-more-articles/" + tags, requestOptions)
    .then(HttpHelper.handleLoggedResponse)
    .then(
      (data) => {
        if (onSuccess) {
          onSuccess(data);
        }
        return data;
      },
      (error) => {
        if (onError) {
          onError(error);
        }
      }
    )
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
}

async function getArticlesByTags(tags, onSuccess, onError) {
  const requestOptions = {
    method: "GET",
    headers: HttpHelper.authHeader(),
  };

  let base_url = "";
  if (process.env.NODE_ENV === "development") {
    base_url = "http://localhost:3002";
  } else {
    base_url = "https://padaw3n-journal.herokuapp.com";
  }
  return fetch(base_url + "/api/v1/articles-by-tags/" + tags, requestOptions)
    .then(HttpHelper.handleLoggedResponse)
    .then(
      (data) => {
        if (onSuccess) {
          onSuccess(data);
        }
        return data;
      },
      (error) => {
        if (onError) {
          onError(error);
        }
      }
    )
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
}

async function getArticlesAdmin(tags, onSuccess, onError) {
  const requestOptions = {
    method: "GET",
    headers: HttpHelper.authHeader(),
  };

  let base_url = "";
  if (process.env.NODE_ENV === "development") {
    base_url = "http://localhost:3002";
  } else {
    base_url = "https://padaw3n-journal.herokuapp.com";
  }
  return fetch(base_url + "/api/v1/articles-admin/" + tags, requestOptions)
    .then(HttpHelper.handleLoggedResponse)
    .then(
      (data) => {
        if (onSuccess) {
          onSuccess(data);
        }
        return data;
      },
      (error) => {
        if (onError) {
          onError(error);
        }
      }
    )
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
}

async function getLastArticleByCategory(tags, onSuccess, onError) {
  const requestOptions = {
    method: "GET",
    headers: HttpHelper.authHeader(),
  };

  let base_url = "";
  if (process.env.NODE_ENV === "development") {
    base_url = "http://localhost:3002";
  } else {
    base_url = "https://padaw3n-journal.herokuapp.com";
  }
  return fetch(base_url + "/api/v1/last-articles-by-category/", requestOptions)
    .then(HttpHelper.handleLoggedResponse)
    .then(
      (data) => {
        if (onSuccess) {
          onSuccess(data);
        }
        return data;
      },
      (error) => {
        if (onError) {
          onError(error);
        }
      }
    )
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
}

/////////////////////////////////////////////////////////////////////////////////////////////////

async function createArticle(formData, onSuccess, onError) {
  const config = {
    headers: HttpHelper.authHeader({ "Content-Type": "multipart/form-data" }),
  };
  console.log("config", config);

  let base_url = "";
  if (process.env.NODE_ENV === "development") {
    base_url = "http://localhost:3002";
  } else {
    base_url = "https://padaw3n-journal.herokuapp.com";
  }

  return axios
    .post(`${base_url}/api/v1/create-article/`, formData, config)
    .then(HttpHelper.handleLoggedResponse)
    .then(
      (data) => {
        if (onSuccess) {
          onSuccess(data);
        }
        return data;
      },
      (error) => {
        if (onError) {
          onError(error);
        }
      }
    )
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
}

async function editArticle(formData, onSuccess, onError) {
  const config = {
    headers: HttpHelper.authHeader({ "Content-Type": "multipart/form-data" }),
    // onUploadProgress: (event) => {
    //     `Current progress:`,
    //     Math.round((event.loaded * 100) / event.total)
    //   );
    // },
  };
  let base_url = "";
  if (process.env.NODE_ENV === "development") {
    base_url = "http://localhost:3002";
  } else {
    base_url = "https://padaw3n-journal.herokuapp.com";
  }

  return axios
    .post(`${base_url}/api/v1/edit-article/`, formData, config)
    .then(HttpHelper.handleLoggedResponse)
    .then(
      (data) => {
        if (onSuccess) {
          onSuccess(data);
        }
        return data;
      },
      (error) => {
        if (onError) {
          onError(error);
        }
      }
    )
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
}

async function connexionHandler(formData, onSuccess, onError) {
  const requestOptions = {
    method: "POST",
    headers: HttpHelper.authHeader(),
    body: JSON.stringify(formData),
  };

  let base_url = "";
  if (process.env.NODE_ENV === "development") {
    base_url = "http://localhost:3002";
  } else {
    base_url = "https://padaw3n-journal.herokuapp.com";
  }

  return fetch(base_url + "/api/v1/auth/", requestOptions)
    .then(HttpHelper.handleLoggedResponse)
    .then(
      (data) => {
        if (onSuccess) {
          onSuccess(data);
        }
        return data;
      },
      (error) => {
        if (onError) {
          onError(error);
        }
      }
    )
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
}

//////////////// DELETE ///////////////////////////////////

async function deleteArticle(id, onSuccess, onError) {
  const requestOptions = {
    method: "DELETE",
    headers: HttpHelper.authHeader(),
  };

  let base_url = "";
  if (process.env.NODE_ENV === "development") {
    base_url = "http://localhost:3002";
  } else {
    base_url = "https://padaw3n-journal.herokuapp.com";
  }
  return fetch(base_url + "/api/v1/delete-article/" + id, requestOptions)
    .then(HttpHelper.handleLoggedResponse)
    .then(
      (data) => {
        if (onSuccess) {
          onSuccess(data);
        }
        return data;
      },
      (error) => {
        if (onError) {
          onError(error);
        }
      }
    )
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
}

export const articleService = {
  get: {
    getArticles,
    getLastArticles,
    getArticle,
    readMoreArticles,
    getArticlesByTags,
    getArticlesAdmin,
    getLastArticleByCategory,
  },
  post: { createArticle, editArticle, connexionHandler },
  delete: { deleteArticle },
};
