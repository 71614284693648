import React from 'react';
import './LoadingView.css';

import Loader from '../../components/loader/Loader';

class LoadingView extends React.Component {

    render() {
        return (
            <div className="content-loading-view">
                    <title>{this.props.title}</title>
                <Loader />
            </div>
        );
    }
}

export default LoadingView;