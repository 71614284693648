import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";

const Link = ({ children, to, dontSaveReferer, ...rest }) => {
  const search =
    to !== null && to.split("?").length > 1 ? "?" + to.split("?")[1] : null;
  const pathname = to !== null ? to.split("?")[0] : to;
  return (
    <ReactRouterLink
      {...rest}
      to={{
        pathname: pathname,
        search: search,
        state: { referrer: document.location.pathname },
      }}
    >
      {children}
    </ReactRouterLink>
  );
};

export default Link;
