import React from "react";

import "./mentionLegal.css";
import Logo3d from "../Logo3d";
import Link from "../Link";
import { ROUTES } from "../../app/routing";

class MentionLegal extends React.Component {
  constructor(props) {
    super(props);
    let data = window.location.href.split("/");
    this.state = {
      isLoaded: false,
      article: this.props.article ? this.props.article : null,
      readMoreArticles: this.props.readMoreArticles
        ? this.props.readMoreArticles
        : null,
      id: data[4],
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className={`font-back + ${"NFT"}`}>
          <div className="main-content-article wrapper">
            <div className="column-left">
              <div className="padding-20">
                <div className="writed-by-section">
                  <img
                    className="img-writed-by-article"
                    src="https://i.seadn.io/gae/asKy65v3v5g_xeJB7cnZfKrXet3Jm6Qj3lOtAGveO_vBIl-yGbJAZ1NE6InOGZjy60Efnc0qsxt__-eqNchhA5c8txLJ0a9103KoCQ?auto=format&w=1000"
                    alt="img"
                  />
                  <div className="writed-by-title">By The KOON</div>
                </div>
              </div>
            </div>
            <div className="article-body">
              <div className="padding-20">
                <div className="head-article">
                  <Link to={ROUTES.HOME} className="link">
                    <div className="logo-big">KOON</div>
                    <Logo3d width={200} height={150} />
                  </Link>
                </div>
                <div className="content-text">
                  {/* <div className="time-read">3 MIN</div> */}
                  <div className={`title  ${"NFT"}`}>{"Mention Légal"}</div>
                  <div className={`intro  ${"NFT"}`}>
                    {
                      "Koon.xyz est un site d’information indépendant édité par Ilan Campillo."
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-content-article">
          <div className="column-left"></div>
          <div className="article-body">
            <div className="padding-20">
              <div className={`content-text ${""}`}>
                <p>
                  Koon.xyz est un site d’information indépendant édité par Ilan
                  Campillo.
                </p>{" "}
                <br />
                <p>
                  Tél : 06 95 02 01 10 – e-mail : ilancampillo@gmail.com
                </p>{" "}
                <br />
                <p>
                  Editeur : 8 rue Chabert, Marseille 13008 , , Siège social : 7
                  bis rue du Louvre, 75001 Paris
                </p>
                <br />
                <p>
                  Le site www.the.koon.xyz est hébergé par Heroku, Inc. dont le
                  siège social est situé à The Landmark @ 1 Market St., Suite
                  300, San Francisco, CA 94105, États-Unis.
                </p>{" "}
                <br />
                <p>Numéro de téléphone: +44 800 808-5158</p>
                <br />
                <h1>RGPD</h1>
                <br />
                <p>
                  The Koon traite les données recueillies pour l’analyse du
                  trafic dans le but d’optimiser la qualité de l’information
                  diffusée.
                </p>
                <p>
                  Les données collectées seront communiquées aux seuls
                  destinataires suivants: Ilan Campillo, Grégoire Tournier, Hugo
                  Pellegrin.
                </p>{" "}
                Les données sont conservées pendant 3 ans. Pour en savoir plus
                sur la gestion de vos données personnelles et pour exercer vos
                droits, consultez le site cnil.fr pour plus d’informations sur
                vos droits. En cas de collecte (directe ou indirecte) des
                données d’un internaute, le RGPD établit une liste précise des
                informations à fournir si elles sont réclamées, telles que :
                l’identité de l’organisme responsable du traitement des données,
                les finalités des données collectées ou encore la durée de
                conservation de ces données. Sélection d’articles pour remplir
                le site Faire les mentions légales KOON Mentions légales
                personne physique 👍 L’identité : Campillo Ilan Les coordonnées
                : 8 rue Chabert, Marseille 13008 , ilancampillo@gmail.com , 06
                95 02 01 10, Les mentions relatives à la propriété
                intellectuelle : en cas d’utilisation d’images, d’illustrations,
                de photographies ou de textes, Les mentions relatives à
                l’hébergement du site : nom de l’hébergeur, raison sociale,
                adresse et numéro de téléphone. Mentions obligatoires site
                d’information L’identité : raison sociale, forme juridique,
                montant du capital social, Les coordonnées : adresse du siège
                social, Les mentions relatives à la propriété intellectuelle :
                en cas d’utilisation d’images, d’illustrations, de photographies
                ou de textes, Les mentions relatives à l’hébergement du site :
                nom de l’hébergeur, raison sociale, adresse et numéro de
                téléphone Site d’information: nom du directeur de la publication
                (ou le nom du responsable de la rédaction) et les coordonnées de
                l’hébergeur du site) RGPD En cas de collecte (directe ou
                indirecte) des données d’un internaute, le RGPD établit une
                liste précise des informations à fournir si elles sont
                réclamées, telles que : l’identité de l’organisme responsable du
                traitement des données, les finalités des données collectées ou
                encore la durée de conservation de ces données.
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MentionLegal;
