import { USER_ROLES_ENUM } from "../constants";
import { LocalStorageService } from "../services/storage.service";

class UserHelper { }

UserHelper.getUser = () => {
    return LocalStorageService.getJson('user');
}

UserHelper.generateFakeUser = () => {
    const fakeUser = {
        id: 5,
        fullname: 'Antoine Poupel',
        firstname: 'Antoine',
        lastname: 'Poupel',
        email: 'antoine.poupel@corellis.eu',
        status: 'active',
        initial: 'A',
        color: 'background-5',
        profile_picture_url: null,
        team: {
            id: 1,
            title: 'Corellis'
        },
        role: 'admin',
        user_guide_percent_completion: 11,
        token: "FAKE_BEARER_TOKEN"
    };
    localStorage.setItem('user', JSON.stringify(fakeUser));
}

UserHelper.setUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
}

UserHelper.userIsCorellis = () => {
    const user = UserHelper.getUser();
    return user && user.email.includes('@corellis.eu')
}

UserHelper.updateUser = (user) => {
    const currentUser = UserHelper.getUser() || {};
    const newUser = Object.assign(currentUser, user);
    UserHelper.setUser(newUser);
}

UserHelper.deleteUser = () => {
    localStorage.removeItem('user');
}


UserHelper.updateCurrentAccount = (account) => {
    localStorage.setItem('current_account_id', account.id);
}


UserHelper.getCurrentAccount = () => {
    const user = UserHelper.getUser();
    const savedAccountId = LocalStorageService.get('current_account_id');
    if (!user) {
        return null;
    }
    if (!user.accounts) {
        return null;
    }
    if (savedAccountId) {
        for (const account of user.accounts) {
            if (account.id == savedAccountId) {
                return account;
            }
        }
    }
    return user.accounts[0];
}

UserHelper.isAdmin = () => {
    const currentAccount = UserHelper.getCurrentAccount();
    if (currentAccount){
        return currentAccount.user_role === USER_ROLES_ENUM.ADMIN;
    }
    return false;
}

UserHelper.getAllUserAccounts = () => {
    const user = UserHelper.getUser();
    if (!user) {
        return [];
    }
    if (!user.accounts) {
        return [];
    }
    return user.accounts;
}

export default UserHelper;