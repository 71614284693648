import React from "react";
import Link from "../Link";
import "./titleCategory.css";

class TitleCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: this.props.tag ? this.props.tag : null,
      title: this.props.title ? this.props.title : "",
      subtitle: this.props.subtitle ? this.props.subtitle : "",
    };
  }

  render() {
    return (
      <div className="central-content-1200">
        <div className="title-category">
          <div className="parallelogram-title-petit">
            <div className={`category-title ${this.state.tag}`}>
              {this.state.title}
            </div>
            <div className={`category-subtitle ${this.state.tag}`}>
              {this.state.subtitle}
            </div>
          </div>
          {this.props.seeMore ? (
            <Link
              className={`see-more ${this.state.tag}`}
              to={`/articles/${this.state.tag}`}
            >
              Voir plus...
            </Link>
          ) : null}
        </div>
      </div>
    );
  }
}

export default TitleCategory;
