import React, { Fragment } from "react";
import "./adminView.css";
import { adminService } from "../../app/services/admin.service";
import { useNavigate } from "react-router-dom";
import CreateArticle from "../../components/admin/createArticle/CreateArticle";
import Header from "../../components/header/Header";
import AllArticlesList from "../../components/admin/allArticlesList/AllArticlesList";
import { articleService } from "../../app/services/articles.service";
import LoadingView from "../loading/LoadingView";

class AdminView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      activePage: "allArticles",
      articles: [],
    };
    this.checkToken = this.checkToken.bind(this);
    this.changePage = this.changePage.bind(this);
    this.fetchArticles = this.fetchArticles.bind(this);
  }

  componentDidMount() {
    this.checkToken();
  }

  changePage(e) {
    this.setState({ activePage: e.target.id });
  }

  checkToken() {
    const formData = localStorage.getItem("user");
    adminService.get.checkToken(
      formData,
      (response) => {
        this.fetchArticles();
      },
      (error) => {
        this.props.navigate("/");
      }
    );
  }

  fetchArticles() {
    articleService.get.getArticles(
      (response) => {
        this.setState({
          articles: response.articles,
          isLoaded: true,
        });
        this.getLastArticles();
      },
      (error) => {
        this.setState({ isLoaded: true });
      }
    );
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <Fragment>
          <Header />
          <div className="button-content">
            <button id="createArticle" onClick={(e) => this.changePage(e)}>
              Create article
            </button>
            <button id="allArticles" onClick={(e) => this.changePage(e)}>
              Tous les articles
            </button>
          </div>
          {this.state.activePage === "createArticle" ? (
            <CreateArticle />
          ) : (
            <AllArticlesList articles={this.state.articles} title={"#ADMIN"} />
          )}
        </Fragment>
      );
    } else {
      return <LoadingView title={"journal crypto"} />;
    }
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <AdminView {...props} navigate={navigate} />;
}

export default WithNavigate;
