import "./App.css";

import HomeView from "../views/home/HomeView";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ROUTES } from "./routing";
import { connect } from "react-redux";
import { history } from "./helpers/history";
import AdminView from "../views/admin/AdminView";
import ArticleView from "../views/ArticleView/ArticleView";
import LogAdmin from "../views/logAdmin/LogAdmin";
import ArticlesView from "../views/ArticlesView/ArticlesView";
import EditArticleView from "../views/EditArticleView/EditArticleView";
import MentionLegalView from "../views/MentionLegalView/MentionLegalView";

const App = () => {
  return (
    <Router history={history}>
      <Routes>
        <Route exact path={ROUTES.HOME} element={<HomeView />} />
        <Route exact path={ROUTES.ADMIN} element={<AdminView />} />
        <Route exact path={ROUTES.LOG_ADMIN} element={<LogAdmin />} />
        <Route path={ROUTES.ARTICLE} element={<ArticleView />} />
        <Route path={ROUTES.ARTICLES} element={<ArticlesView />} />
        <Route
          exact
          path={ROUTES.MENTION_LEGAL}
          element={<MentionLegalView />}
        />
        <Route
          exact
          path={ROUTES.ADMIN_EDIT_ARTICLE}
          element={<EditArticleView />}
        />
        {/* Catch all route */}
        {/* <PublicRoute path="*" component={NotFoundView} status={404} /> */}
      </Routes>
    </Router>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(App);
