import React, { Fragment } from "react";
import Header from "../../components/header/Header";
import LoadingView from "../loading/LoadingView";
import "./logAdmin.css";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { articleService } from "../../app/services/articles.service";

class LogAdminView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: true,
      inlineLoading: false,
      username: "",
      password: "",
    };
    this.handleChangeUsername = this.handleChangeUsername.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.connexionHandler = this.connexionHandler.bind(this);
  }

  componentDidMount() {}

  handleChangeUsername(event) {
    let newFormData = Object.assign({}, this.state.formData);
    newFormData.title = event.target.value;
    this.setState({
      username: newFormData.title,
    });
  }

  handleChangePassword(event) {
    let newFormData = Object.assign({}, this.state.formData);
    newFormData.intro = event.target.value;
    this.setState({
      password: newFormData.intro,
    });
  }

  connexionHandler(e) {
    e.preventDefault();
    const formData = {
      username: this.state.username,
      password: this.state.password,
    };
    articleService.post.connexionHandler(
      formData,
      (response) => {
        if (response.status === "error") {
          toast.error("Erreur password/username");
        } else {
          localStorage.setItem("user", JSON.stringify(response.user));
          toast.success("connexion réussi");
          this.props.navigate("/admin");
        }
      },
      (error) => {
        this.setState({ isLoaded: true });
      }
    );
  }

  render() {
    let disabled = false;
    if (this.state.isLoaded) {
      return (
        <Fragment>
          <Toaster />
          <Header />
          <div className="central-content-1200 padding-top-40 content">
            <form className="form-input">
              <label className="padding-bottom-5 display-block font-inter-600 color-969696 font-size-18">
                username
              </label>
              <input
                placeholder="Enter le titre de l'article.."
                value={this.state.title}
                onChange={this.handleChangeUsername}
                className="input-wallet"
              />
              <label className="padding-bottom-5 display-block font-inter-600 color-969696 font-size-18">
                password
              </label>
              <input
                placeholder="Enter l'intro"
                value={this.state.intro}
                onChange={this.handleChangePassword}
                className="input-wallet"
              />
              <button
                className="create-btn"
                disabled={disabled}
                onClick={(e) => this.connexionHandler(e)}
              >
                connexion
              </button>
            </form>
          </div>
        </Fragment>
      );
    } else {
      return <LoadingView title={"journal crypto"} />;
    }
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <LogAdminView {...props} navigate={navigate} />;
}

export default WithNavigate;
