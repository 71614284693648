import React from "react";
import { motion } from "framer-motion";

import Link from "../Link";

import "./articleBox.css";
import setImgUrl from "../../services/imgUrl.service";

class ArticleBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      article: this.props.article ? this.props.article : {},
    };
  }

  componentDidMount() {}

  render() {
    if (this.state.article) {
      const article = this.state.article;
      const img_url = setImgUrl(article);
      return (
        <motion.div
          className="box"
          initial={{ opacity: 0, scale: 1 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.6,
            delay: 0.2,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <div className="article-box">
            <Link
              to={`/article/${article._id}`}
              onClick={
                this.props.canReload ? (e) => this.reload(e, article._id) : null
              }
            >
              <img className="img" src={img_url} alt="img" />
              <div className="tag-and-time-article-box">
                <div className="tags-container">
                  {article.tags
                    ? article.tags.map((tag) => {
                        return (
                          <Link
                            className={`tag-display ${tag.label}`}
                            key={tag.label}
                            to={`/articles/${tag.value}`}
                          >
                            <span className="tag font-style-normal">#</span>
                            <span className="tag font-style-italic">
                              {tag.label}
                            </span>
                          </Link>
                        );
                      })
                    : null}
                </div>
                <div className="read-time">3 MIN</div>
              </div>
              <div className="title">
                {article.title.length > 80
                  ? article.title.slice(0, 80) + "..."
                  : article.title}
              </div>
              <div className="content-writed-by">
                <img
                  className="img-writed-by-article-box"
                  src="https://i.seadn.io/gae/asKy65v3v5g_xeJB7cnZfKrXet3Jm6Qj3lOtAGveO_vBIl-yGbJAZ1NE6InOGZjy60Efnc0qsxt__-eqNchhA5c8txLJ0a9103KoCQ?auto=format&w=1000"
                  alt="img"
                />
                <div className="writeBy">By {article.writedBy}</div>
              </div>
            </Link>
          </div>
        </motion.div>
      );
    } else {
      return <div>No article</div>;
    }
  }
}

export default ArticleBox;
