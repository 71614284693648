import React from "react";
import { connect } from "react-redux";
import { ArticleHeader } from "../../components/header/ArticleHeader";
import Article from "../../components/article/Article";
import { articleService } from "../../app/services/articles.service";
import LoadingView from "../loading/LoadingView";
import Footer from "../../components/footer/Footer";
import ScrollProgress from "../../components/scrollProgress/ScrollProgress";

class ArticleView extends React.Component {
  constructor(props) {
    super(props);
    let data = window.location.href.split("/");
    this.state = {
      isLoaded: false,
      inlineLoading: false,
      id: data[4],
      readMoreArticles: [],
      article: null,
    };
    this.fetchArticle = this.fetchArticle.bind(this);
    this.fetchReadMoreArticles = this.fetchReadMoreArticles.bind(this);
  }

  componentDidMount() {
    console.log("Oui NONONO");
    this.fetchArticle();
  }

  fetchArticle() {
    articleService.get.getArticle(
      this.state.id,
      (response) => {
        this.setState({
          article: response.article[0],
        });
        this.fetchReadMoreArticles();
      },
      (error) => {
        this.setState({ isLoaded: true });
      }
    );
  }

  fetchReadMoreArticles() {
    const tags = JSON.stringify(this.state.article.tags);
    articleService.get.readMoreArticles(
      tags,
      (response) => {
        this.setState({
          readMoreArticles: response.articles,
          isLoaded: true,
        });
      },
      (error) => {
        this.setState({ isLoaded: true });
      }
    );
  }
  render() {
    if (this.state.isLoaded) {
      return (
        <div key={this.state.id}>
          <ArticleHeader article={this.state.article} />
          <Article
            article={this.state.article}
            readMoreArticles={this.state.readMoreArticles}
          />
          <ScrollProgress />
          <Footer article={this.state.article} />
        </div>
      );
    } else {
      return <LoadingView title={"journal crypto"} />;
    }
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ArticleView);
