import React, { Fragment } from "react";
import { motion } from "framer-motion";

import ArticleBox from "../articleBox/articleBox";
import setImgUrl from "../../services/imgUrl.service";
import { Link, NavLink } from "react-router-dom";

import "./lastNews.css";

class LastNews extends React.Component {
  constructor(props) {
    super(props);
    const new_articles = this.props.new_articles;
    this.state = {
      main_article: new_articles ? new_articles[0] : null,
      other_articles: new_articles ? new_articles.slice(1) : null,
    };
  }

  componentDidMount() {}

  render() {
    if (this.state.main_article) {
      const img_url = setImgUrl(this.state.main_article);
      return (
        <Fragment>
          <div className="central-content-1200 padding-top-30">
            {this.state.main_article ? (
              <div className="content-column">
                <div className="column-percent-60 responsive-900 padding-bottom-40">
                  <motion.div
                    initial={{ opacity: 0, scale: 1 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                      duration: 0.6,
                      delay: 0.2,
                      ease: [0, 0.71, 0.2, 1.01],
                    }}
                  >
                    <NavLink
                      to={`/article/${this.state.main_article._id}`}
                      className={"text-decoration-none"}
                    >
                      <div className="big-box-actu">
                        <div className="img-content">
                          <img src={img_url} alt="Logo" className="img" />
                        </div>
                        <div
                          className={`tags-container-banner ${this.state.main_article.tags[0].value}`}
                        >
                          {this.state.main_article.tags
                            ? this.state.main_article.tags.map((tag) => {
                                return (
                                  <Link
                                    key={tag.label}
                                    to={`/articles/${tag.value}`}
                                  >
                                    <div className={`tag ${tag.label}`}>
                                      #{tag.label}
                                    </div>
                                  </Link>
                                );
                              })
                            : null}
                          <div className="time-read">3MIN</div>
                        </div>
                        <div className="post-title">
                          {this.state.main_article.title}
                        </div>
                      </div>
                    </NavLink>
                  </motion.div>
                </div>
                <div className="column-percent-40 responsive-900 all-side-box">
                  {this.state.other_articles.map((item) => {
                    const img_url = setImgUrl(item);
                    return (
                      <motion.div
                        initial={{ opacity: 0, scale: 1 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                          duration: 0.6,
                          delay: 0.2,
                          ease: [0, 0.71, 0.2, 1.01],
                        }}
                      >
                        <NavLink
                          key={item._id}
                          id="RouterNavLink"
                          to={`/article/${item._id}`}
                          className={"text-decoration-none"}
                        >
                          <div className="side-box-actu">
                            <img
                              src={img_url}
                              alt="Logo"
                              className="img-side-news"
                            />
                            <div className="content-tag-read-time">
                              <div
                                className={`tags-container-banner ${item.tags[0].value}`}
                              >
                                {item.tags
                                  ? item.tags.map((tag) => {
                                      return (
                                        <Link
                                          key={item._id + tag.value}
                                          to={`/articles/${tag.value}`}
                                        >
                                          <div className={`tag ${tag.label}`}>
                                            #{tag.label}
                                          </div>
                                        </Link>
                                      );
                                    })
                                  : null}
                                <div className="time-read">3MIN</div>
                              </div>
                              <div className="title-side-box">
                                {item.title.length > 80
                                  ? item.title.slice(0, 80) + "..."
                                  : item.title}
                              </div>
                              <div className="content-writed-by">
                                <div className="writeBy">
                                  By {item.writedBy}
                                </div>
                                <img
                                  className="img-writed-by"
                                  src="https://i.seadn.io/gae/asKy65v3v5g_xeJB7cnZfKrXet3Jm6Qj3lOtAGveO_vBIl-yGbJAZ1NE6InOGZjy60Efnc0qsxt__-eqNchhA5c8txLJ0a9103KoCQ?auto=format&w=1000"
                                  alt="img"
                                />
                              </div>
                            </div>
                          </div>
                        </NavLink>
                      </motion.div>
                    );
                  })}
                </div>
                <div className="content-column">
                  {this.state.other_articles.slice(1, 3).map((item) => {
                    return (
                      <div
                        key={item.createdAt}
                        className="article-column text-align-center padding-top-10 padding-bottom-30"
                      >
                        <ArticleBox article={item} />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div>No Articles...</div>
            )}
          </div>
        </Fragment>
      );
    } else {
      return <div></div>;
    }
  }
}

export default LastNews;
