import React, { Fragment } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { articleService } from "../../app/services/articles.service";
import ArticlesList from "../../components/ArticlesList/ArticlesList";
import Footer from "../../components/footer/Footer";
import { ArticleHeader } from "../../components/header/ArticleHeader";
import HeaderKoon from "../../components/header/headerKoon/HeaderKoon";
import TagSelector from "../../components/TagSelector/TagSelector";
import LoadingView from "../loading/LoadingView";

class ArticlesView extends React.Component {
  constructor(props) {
    super(props);
    const tag = this.props.params.tag;
    const page = this.props.searchParams.get("page");
    this.state = {
      isLoaded: false,
      isReFetch: true,
      scrollFetch: false,
      hideFooter: true,
      articlesList: [],
      articlescCount: null,
      meta: { perPage: 9, page: page ? parseInt(page) : 0 },
      category_filter_active: tag ? [{ value: tag, label: tag }] : [],
      category_filter: [
        { label: "Crypto", value: "Crypto" },
        { label: "NFT", value: "NFT" },
        { label: "Defi", value: "Defi" },
      ],
    };
    this.fetchArticles = this.fetchArticles.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.activeFilter = this.activeFilter.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.fetchArticles();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  fetchArticles(actvieTags = null, meta = null) {
    const formData = JSON.stringify({
      meta: meta ? meta : this.state.meta,
      activeTags: actvieTags ? actvieTags : this.state.category_filter_active,
    });
    articleService.get.getArticlesByTags(
      formData,
      (response) => {
        this.setState({
          articlesList: [...this.state.articlesList, ...response.articles],
          articlescCount: response.count,
          isLoaded: true,
          isReFetch: true,
          scrollFetch: false,
        });
        // const searchParams = new URLSearchParams();
        // searchParams.set("page", meta ? meta.page : this.state.meta.page);
        // this.props.setSearchParams(searchParams);
      },
      (error) => {
        console.log("ERRORR ", error);
        this.setState({ isLoaded: true });
      }
    );
  }

  handleScroll() {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset + 5;
    if (
      windowBottom >= docHeight &&
      this.state.articlesList.length < this.state.articlescCount &&
      !this.state.scrollFetch
    ) {
      const meta = {
        page: this.state.meta.page + 1,
        perPage: this.state.meta.perPage,
      };
      this.setState({
        meta,
        scrollFetch: true,
      });
      this.fetchArticles(null, meta);
    }
    if (this.state.articlesList.length === this.state.articlescCount) {
      this.setState({ hideFooter: false });
    }
  }

  activeFilter(e) {
    e.preventDefault();
    this.setState({
      articlesList: [],
      isReFetch: false,
      hideFooter: true,
      meta: { perPage: 9, page: 0 },
    });
    let isActive = this.state.category_filter_active.find(
      (x) => x.value === e.target.id
    );
    if (isActive) {
      let activeTags = this.state.category_filter_active.filter((o) => {
        return o.value !== e.target.id;
      });
      this.setState({
        category_filter_active: activeTags,
      });
      const meta = {
        page: 0,
        perPage: 9,
      };
      this.fetchArticles(activeTags, meta);
    } else {
      let activeTags = this.state.category_filter_active;
      activeTags.push({ value: e.target.id, label: e.target.id });
      this.setState({ category_filter_active: activeTags });
      const meta = {
        page: 0,
        perPage: 9,
      };
      this.fetchArticles(activeTags, meta);
    }
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <Fragment>
          <ArticleHeader />
          <div className="padding-left-20">
            <HeaderKoon displayTag={false} />
          </div>
          <TagSelector
            categoryFilter={this.state.category_filter}
            activeTag={this.state.category_filter_active}
            activeFilter={this.activeFilter}
          />
          <ArticlesList
            articles={this.state.articlesList}
            title={"#TOUS LES ARTICLES"}
            tagsSelector={true}
            activeTag={this.state.category_filter_active}
            fetchArticle={this.fetchArticles}
            activeFilter={this.activeFilter}
            isReFetch={this.state.isReFetch}
            scrollFetch={this.state.scrollFetch}
          />
          {!this.state.hideFooter ? <Footer /> : null}
        </Fragment>
      );
    } else {
      return <LoadingView title={"journal crypto"} />;
    }
  }
}

function WithSearchParams(props) {
  let [searchParams, setSearchParams] = useSearchParams();
  let params = useParams();
  return (
    <ArticlesView
      {...props}
      params={params}
      searchParams={searchParams}
      setSearchParams={setSearchParams}
    />
  );
}

export default WithSearchParams;
