import React from "react";

import "./article.css";
import moment from "moment";
import Parser from "html-react-parser";
import ArticlesList from "../ArticlesList/ArticlesList";
import TItleCategory from "../TitleCategory/TitleCategory";
import HeaderKoon from "../header/headerKoon/HeaderKoon";
import { motion, useScroll, useSpring } from "framer-motion";
import setImgUrl from "../../services/imgUrl.service";

const Article = (props) => {
  let article = props.article ? props.article : null;
  let readMoreArticles = props.readMoreArticles ? props.readMoreArticles : null;

  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  if (article) {
    const img_url = setImgUrl(article);
    return (
      <>
        <div
          key={article._id}
          id={article._id}
          className={`font-back + ${article.tags[0].label}`}
        >
          <motion.div className="progress" style={{ scaleX }} />
          <div className="main-content-article wrapper">
            <div className="column-left">
              <div className="padding-20">
                <div className="writed-by-section">
                  <img
                    className="img-writed-by-article"
                    src="https://i.seadn.io/gae/asKy65v3v5g_xeJB7cnZfKrXet3Jm6Qj3lOtAGveO_vBIl-yGbJAZ1NE6InOGZjy60Efnc0qsxt__-eqNchhA5c8txLJ0a9103KoCQ?auto=format&w=1000"
                    alt="img"
                  />
                  <div className="writed-by-title">By Padaw3n</div>
                </div>
              </div>
            </div>
            <div className="article-body">
              <div className="padding-20">
                <HeaderKoon activeTags={article.tags} displayTag={true} />
                <div className="content-text">
                  <div className="content-read-published">
                    <div className="published">
                      {moment(article.createdAt).format("DD-MM-YYYY")}
                    </div>
                  </div>
                  <div className={`title  ${article.tags[0].value}`}>
                    {article.title}
                  </div>
                  <div className={`intro  ${article.tags[0].value}`}>
                    {article.intro}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-content-article">
          <div className="column-left"></div>
          <div className="article-body">
            <div className="padding-20">
              <img className="content-image main-image" src={img_url} alt="" />
              <div className={`content-text ${article.tags[0].value}`}>
                {Parser(article.articleContent)}
              </div>
            </div>
          </div>
        </div>
        <div className="preview-article">
          <div className="main-content-article">
            <div className="column-left">
              <div className="padding-20"></div>
            </div>
            <div className="writed-by-section-big">
              <img
                className="img-writed-by-big"
                src="https://i.seadn.io/gae/asKy65v3v5g_xeJB7cnZfKrXet3Jm6Qj3lOtAGveO_vBIl-yGbJAZ1NE6InOGZjy60Efnc0qsxt__-eqNchhA5c8txLJ0a9103KoCQ?auto=format&w=1000"
                alt="img"
              />
              <div className="text-writed-by">
                <div className="writed-by-title-big">By Padaw3n</div>
                <div className="writed-by-descr-big">
                  Enthousiaste depuis toujours pour les nouvelles technologies,
                  je me suis progressivement rapproché des activités liées au
                  Web3 lors de l'année 2021. Avec le temps,{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lire-aussi">
          <TItleCategory
            title="#Lire aussi"
            subtitle="Le journal qui decripte le web3"
            tag={article.tags[0].value}
          />
          <ArticlesList
            articles={readMoreArticles}
            actvieTag={{
              value: article.tags[0].value,
              label: article.tags[0].label,
            }}
            isReFetch={true}
            canReload={true}
          />
        </div>
      </>
    );
  }
};

export default Article;
