import { globalConstants } from "../constants";
import HttpHelper from "../helpers/http.helper";

async function checkToken(formData, onSuccess, onError) {
  const requestOptions = {
    method: "POST",
    headers: HttpHelper.authHeader(),
    body: JSON.stringify(formData),
  };

  let base_url = "";
  if (process.env.NODE_ENV === "development") {
    base_url = "http://localhost:3002";
  } else {
    base_url = "https://padaw3n-journal.herokuapp.com";
  }

  return fetch(base_url + "/api/v1/check-token/", requestOptions)
    .then(HttpHelper.handleLoggedResponse)
    .then(
      (data) => {
        if (onSuccess) {
          onSuccess(data);
        }
        return data;
      },
      (error) => {
        if (onError) {
          onError(error);
        }
      }
    )
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
}

export const adminService = {
  get: { checkToken },
  post: {},
};
