import React from "react";

import { useNavigate } from "react-router-dom";

import ArticleBox from "../articleBox/articleBox";
import MDSpinner from "react-md-spinner";

import "./articlesList.css";

class ArticlesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isReFetch: this.props.isReFetch ? true : false,
      tagsSelector: this.props.tagsSelector ? true : false,
      articlesList: this.props.articles ? this.props.articles : [],
    };
    this.reload = this.reload.bind(this);
  }

  componentDidMount() {}

  reload(e, id) {
    e.preventDefault();
    this.props.navigate(`/article/${id}`);
    window.location.reload();
  }

  render() {
    if (this.props.isReFetch) {
      return (
        <div className="central-content-1200">
          <div className="content-column">
            <div className="text-align-center padding-top-10">
              {this.props.articles.map((item) => {
                return (
                  <div
                    key={item._id}
                    className="column-percent-33 responsive-900d padding-top-10 padding-bottom-20"
                  >
                    <ArticleBox
                      article={item}
                      reload={this.reload}
                      canReload={this.props.canReload}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {this.props.scrollFetch ? (
            <div className="spinner-content">
              <MDSpinner />
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <div className="spinner-content">
          <MDSpinner />
        </div>
      );
    }
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <ArticlesList {...props} navigate={navigate} />;
}

export default WithNavigate;
