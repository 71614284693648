import React from "react";
import { useNavigate } from "react-router-dom";

import Link from "../Link";
import { ROUTES } from "../../app/routing";
import Logo3d from "../Logo3d";
import Burger from "../burger/burger";

import "./header.css";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.redirectToHomePage = this.redirectToHomePage.bind(this);
  }

  redirectToHomePage() {
    this.props.navigate("/");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  render() {
    return (
      <div className="central-content-1400">
        <div className="line-height-53 logo-pos text-align-center header">
          <div className="link-content" onClick={this.redirectToHomePage}>
            <div className="logo-title">
              TheKoon.
              <span className="logo-title span-x">x</span>
              <span className="logo-title span-y">y</span>
              <span className="logo-title span-z">z</span>
            </div>
            <div className="img-3d">
              <Logo3d width={70} height={55} />
            </div>
          </div>
          <div className="padding-left-30">
            <Burger />
          </div>
          {/* <Slider /> */}
        </div>
      </div>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <Header {...props} navigate={navigate} />;
}

export default WithNavigate;
