import React, { Fragment } from "react";
import { connect } from "react-redux";
import { ArticleHeader } from "../../components/header/ArticleHeader";
import LoadingView from "../loading/LoadingView";
import Footer from "../../components/footer/Footer";
import MentionLegal from "../../components/mentionLegal/MentionLegal";

class MentionLegalView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.setState({
      isLoaded: true,
    });
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <Fragment>
          <ArticleHeader />
          <MentionLegal />
          <Footer />
        </Fragment>
      );
    } else {
      return <LoadingView title={"journal crypto"} />;
    }
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(MentionLegalView);
