import { ROUTES } from "../routing";
import UserHelper from "./UserHelper";

class HttpHelper {}

HttpHelper.getUrlParameters = (locationSearch) => {
  if (!locationSearch) {
    return {};
  }
  const search = locationSearch.substring(1);
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
};

HttpHelper.addParametersToUrl = (baseUrl, params) => {
  if (!params || (params && Object.keys(params).length === 0)) {
    return baseUrl;
  }
  for (const [key, value] of Object.entries(params)) {
    if (value === null) {
      // je remove les value null car le null va se transformer en string 'null' dans l'url
      delete params[key];
    }
  }
  return Object.keys(params).length > 0
    ? baseUrl + "?" + new URLSearchParams(params)
    : baseUrl;
};

HttpHelper.authHeader = (customHeaders) => {
  customHeaders = customHeaders || {};
  // return authorization header with jwt token
  const user = UserHelper.getUser();
  if (user && user.token) {
    return Object.assign({ "x-access-token": user.token }, customHeaders);
  } else {
    return customHeaders;
  }
};

HttpHelper.handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 404) {
        window.location.href = ROUTES.OOPS;
      } else if (response.status === 500) {
        window.location.href = ROUTES.ARF;
      } else if (response.status === 403) {
        window.location.href = ROUTES.UNAUTHORIZED;
      } else if (response.status === 401) {
        window.location.href = ROUTES.UNAUTHORIZED;
      } else {
        return Promise.reject(data);
      }
    }
    return data;
  });
};

HttpHelper.handleLoggedVanillaResponse = (response) => {
  if (!response.ok) {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      window.location.reload(true);
    } else if (response.status === 404) {
      window.location.href = ROUTES.OOPS;
    } else if (response.status === 500) {
      window.location.href = ROUTES.ARF;
    } else if (response.status === 403) {
      window.location.href = ROUTES.UNAUTHORIZED;
    } else {
      response.text().then((text) => {
        const data = text && JSON.parse(text);
        const error = data && data.message ? data.message : response.statusText;
        return Promise.reject(error);
      });
    }
  }
  return response;
};

HttpHelper.handleLoggedResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        window.location.reload(true);
      } else if (response.status === 404) {
        window.location.href = ROUTES.OOPS;
      } else if (response.status === 500) {
        window.location.href = ROUTES.ARF;
      } else if (response.status === 403) {
        window.location.href = ROUTES.UNAUTHORIZED;
      } else {
        const error = data && data.message ? data.message : response.statusText;
        return Promise.reject(error);
      }
    }
    return data;
  });
};

HttpHelper.getErrorMessage = (error) => {
  return typeof error == "string" ? error : error.message;
};

HttpHelper.getErrorStatus = (error) => {
  return typeof error == "string" ? "unknown" : error.status;
};

export default HttpHelper;
