import React, { useState, useEffect } from "react";
import "./header.css";
import Header from "./Header";

export const ArticleHeader = ({ article }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      setIsShown(window.scrollY > 102);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div id="article-header" className={isShown ? `shown ` : ""}>
      <Header />
    </div>
  );
};
