import React from "react";
import MDSpinner from "react-md-spinner";

import "./loader.css";

class Loader extends React.Component {
  render() {
    const { className, color } = this.props;
    let allClassNames = ["website-loader"];
    if (className) {
      allClassNames.push(className);
    }

    return (
      <div className={allClassNames.join(" ")} viewBox="0 0 24 24">
        <MDSpinner />
      </div>
    );
  }
}

export default Loader;
