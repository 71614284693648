import React, { Fragment } from "react";
import { connect } from "react-redux";
import HeaderKoon from "../../components/header/headerKoon/HeaderKoon";
import LastNews from "../../components/lastNews/LastNews";
import ArticlesList from "../../components/ArticlesList/ArticlesList";
import { articleService } from "../../app/services/articles.service";
import LoadingView from "../loading/LoadingView";
import { ArticleHeader } from "../../components/header/ArticleHeader";
import Footer from "../../components/footer/Footer";
import TitleCategory from "../../components/TitleCategory/TitleCategory";

class HomeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isReFetch: false,
      articles: [],
      new_articles: [],
      newArticlesNft: [],
      newArticlesCrypto: [],
      newArticlesDefi: [],
    };
    this.getLastArticles = this.getLastArticles.bind(this);
    this.getLastArticlesByTag = this.getLastArticlesByTag.bind(this);
  }

  componentDidMount() {
    this.getLastArticles();
  }

  getLastArticles() {
    articleService.get.getLastArticles(
      (response) => {
        this.setState({
          new_articles: response.articles,
        });
        this.getLastArticlesByTag();
      },
      (error) => {
        this.setState({ isLoaded: true });
      }
    );
  }

  getLastArticlesByTag() {
    articleService.get.getLastArticleByCategory(
      (response) => {
        this.setState({
          newArticlesNft: response.articles.lastArticlesNft,
          newArticlesCrypto: response.articles.lastArticlesCrypto,
          newArticlesDefi: response.articles.lastArticlesDefi,
          isLoaded: true,
          isReFetch: true,
        });
      },
      (error) => {
        this.setState({
          newArticlesNft: error.articles.lastArticlesNft,
          newArticlesCrypto: error.articles.lastArticlesCrypto,
          newArticlesDefi: error.articles.lastArticlesDefi,
          isLoaded: true,
          isReFetch: true,
        });
      }
    );
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <Fragment>
          <ArticleHeader />
          <HeaderKoon activeTags={null} displayTag={true} />
          {/* <div style={{ marginLeft: "-8px" }}>
            <TitleCategory
              subtitle="Séléctionnez pour vous !"
              title="#NEWS"
              tag="News"
              seeMore={false}
            />
          </div> */}
          <LastNews new_articles={this.state.new_articles} />
          <div className="padding-top-70" />
          <TitleCategory
            subtitle="La finance décentralisée décryptée !"
            title="#DEFI"
            tag="Defi"
            seeMore={true}
          />
          <ArticlesList
            articles={this.state.newArticlesDefi}
            actvieTag={{ value: "Defi", label: "Defi" }}
            isReFetch={true}
          />
          <div className="padding-top-50" />
          <TitleCategory
            title="#NFT"
            subtitle="Les enjeux d'une révolution numérique !"
            tag="NFT"
            seeMore={true}
          />
          <ArticlesList
            articles={this.state.newArticlesNft}
            actvieTag={{ value: "NFT", label: "NFT" }}
            isReFetch={true}
          />
          <div className="padding-top-50" />
          <TitleCategory
            title="#CRYPTO"
            subtitle="On décode le web3 !"
            tag="Crypto"
            seeMore={true}
          />
          <ArticlesList
            articles={this.state.newArticlesCrypto}
            actvieTag={{ value: "Crypto", label: "Crypto" }}
            isReFetch={true}
          />
          <Footer />
        </Fragment>
      );
    } else {
      return <LoadingView title={"journal crypto"} />;
    }
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(HomeView);
