import React, { Fragment } from "react";
import { connect } from "react-redux";
import { ArticleHeader } from "../../components/header/ArticleHeader";
import EditArticle from "../../components/admin/EditArticle/EditArticle";
import { articleService } from "../../app/services/articles.service";
import LoadingView from "../loading/LoadingView";
import { adminService } from "../../app/services/admin.service";

class EditArticleView extends React.Component {
  constructor(props) {
    super(props);
    let data = window.location.href.split("/");
    this.state = {
      isLoaded: false,
      article: [],
      article_id: data[4] ? data[4] : null,
    };
    this.checkToken = this.checkToken.bind(this);
    this.fetchArticle = this.fetchArticle.bind(this);
  }

  componentDidMount() {
    this.checkToken();
  }

  checkToken() {
    const formData = localStorage.getItem("user");
    adminService.get.checkToken(
      formData,
      (response) => {
        this.fetchArticle();
      },
      (error) => {
        this.props.navigate("/");
      }
    );
  }

  fetchArticle() {
    articleService.get.getArticle(
      this.state.article_id,
      (response) => {
        console.log(response);
        this.setState({
          isLoaded: true,
          article: response.article[0],
        });
      },
      (error) => {
        this.setState({ isLoaded: true });
      }
    );
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <Fragment>
          <ArticleHeader article={this.state.article} />
          <EditArticle article={this.state.article} />
        </Fragment>
      );
    } else {
      return <LoadingView title={"journal crypto"} />;
    }
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(EditArticleView);
