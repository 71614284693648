import React, { Fragment } from "react";
import { articleService } from "../../../app/services/articles.service";
import Link from "../../Link";
import setImgUrl from "../../../services/imgUrl.service";
import "./allArticlesList.css";

class AllArticlesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      tagsSelector: true,
      title: this.props.title ? this.props.title : "",
      articlesList: [], //articles ? articles : [],
      category_filter: [
        { label: "Defi", value: "Defi" },
        { label: "NFT", value: "NFT" },
        { label: "Crypto", value: "Crypto" },
      ],
      category_filter_active: this.props.actvieTag
        ? [this.props.actvieTag]
        : [],
      tag: this.props.tag ? this.props.tag : "",
    };
    this.activeFilter = this.activeFilter.bind(this);
    this.fetchArticlesByTags = this.fetchArticlesByTags.bind(this);
  }

  componentDidMount() {
    if (this.state.tagsSelector) {
      this.fetchArticlesByTags();
    } else {
      this.setState({ articlesList: this.props.articles });
    }
  }

  componentWillUnmount() {
    this.setState({
      isLoaded: false,
      tagsSelector: false,
      title: "",
      articlesList: [],
      category_filter: [
        { label: "Defi", value: "Defi" },
        { label: "NFT", value: "NFT" },
        { label: "Crypto", value: "Crypto" },
      ],
      category_filter_active: [],
      tag: "",
    });
  }

  fetchArticlesByTags(activeTags = null) {
    if (activeTags === null) {
      activeTags = JSON.stringify(this.state.category_filter_active);
    } else {
      activeTags = JSON.stringify(activeTags);
    }
    articleService.get.getArticlesAdmin(
      activeTags,
      (response) => {
        this.setState({
          articlesList: response.articles,
          isLoaded: true,
        });
      },
      (error) => {
        console.log("ERRORR ", error);
        this.setState({ isLoaded: true });
      }
    );
  }

  activeFilter(e) {
    e.preventDefault();
    let isActive = this.state.category_filter_active.find(
      (x) => x.value === e.target.id
    );
    if (isActive) {
      let activeTags = this.state.category_filter_active.filter((o) => {
        return o.value !== e.target.id;
      });
      this.setState({
        category_filter_active: activeTags,
      });
      this.fetchArticlesByTags(activeTags);
    } else {
      let activeTags = this.state.category_filter_active;
      activeTags.push({ value: e.target.id, label: e.target.id });
      this.setState({ category_filter_active: activeTags });
      this.fetchArticlesByTags(activeTags);
    }
  }

  render() {
    return (
      <Fragment>
        <div className="central-content-1200">
          <div className="parallelogram-title-petit">
            <div className={`category-title ${this.state.tag}`}>
              {this.state.title}
            </div>
          </div>
          {this.state.tagsSelector ? (
            <div className="tag-selector">
              <div className="content-column">
                {this.state.category_filter.map((item) => {
                  let test = this.state.category_filter_active.find(
                    (x) => x.value === item.value
                  );
                  return (
                    <div className="column-percent-30 padding-top-30">
                      <div
                        className={`tag-selector-btn ${test ? true : false}`}
                        id={item.value}
                        onClick={(e) => this.activeFilter(e)}
                      >
                        {item.value}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}

          <div className="content-column-article-box-admin">
            <div className="central-content-1400 text-align-center padding-top-20">
              {this.state.articlesList.map((item) => {
                const img_url = setImgUrl(item);
                return (
                  <div
                    key={item.createdAt}
                    className="column-percent-33 text-align-center padding-top-40"
                  >
                    <div className="article-box">
                      <Link to={`/admin-article/${item._id}`}>
                        <img className="img" src={img_url} alt="img" />
                        <div className="tag-and-time-article">
                          <div className="tags-container1">
                            {item.tags
                              ? item.tags.map((tag) => {
                                  return (
                                    <div
                                      key={tag.label}
                                      className={`tag ${tag.label}`}
                                    >
                                      #{tag.label}
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                          <div className="read-time">3 MIN</div>
                        </div>
                        <div className="title">{item.title}</div>
                        <div className="content-writed-by">
                          <img
                            className="img-writed-by"
                            src="https://i.seadn.io/gae/asKy65v3v5g_xeJB7cnZfKrXet3Jm6Qj3lOtAGveO_vBIl-yGbJAZ1NE6InOGZjy60Efnc0qsxt__-eqNchhA5c8txLJ0a9103KoCQ?auto=format&w=1000"
                            alt="img"
                          />
                          <div className="writeBy">By {item.writedBy}</div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="">
            <div></div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AllArticlesList;
