import React, { useEffect, useState } from "react";
import "./headerKoon.css";
import Link from "../../Link";
import { ROUTES } from "../../../app/routing";
import Logo3d from "../../Logo3d";
import Burger from "../../burger/burger";
import { motion } from "framer-motion";

const HeaderKoon = (props) => {
  const windowWidth =
    "innerWidth" in window
      ? window.innerWidth
      : document.documentElement.offsetWidth;
  let logoWidth = 180;
  let logoHeight = 130;
  if (windowWidth <= 600) {
    logoWidth = 150;
    logoHeight = 100;
  }
  if (windowWidth <= 500) {
    logoWidth = 130;
    logoHeight = 80;
  }

  const [isShown, setIsShown] = useState(false);
  useEffect(() => {
    const handleScroll = (event) => {
      setIsShown(window.scrollY > 102);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let activeTags = [];
  if (!props.activeTags) {
    activeTags = [{ label: "Crypto" }, { label: "NFT" }, { label: "Defi" }];
  } else {
    activeTags = props.activeTags;
  }
  return (
    <div className="central-content-1200 padding-bottom-30">
      <div className="head-article">
        <div className="header-flex-content">
          <Link to={ROUTES.HOME} className="text-decoration-none">
            <div className="logo">
              TheKoon.
              <span className="logo span-x">x</span>
              <span className="logo span-y">y</span>
              <span className="logo span-z">z</span>
              {/* <div className="logo-koon-3d padding-top-10">
                <Logo3d width={logoWidth} height={logoHeight} />
              </div> */}
            </div>
          </Link>
          <div className="rubrique-home">
            {props.displayTag === true && activeTags.length > 0
              ? activeTags.map((item) => {
                  return (
                    <motion.div
                      key={item.label}
                      className="box"
                      /**
                       * Setting the initial keyframe to "null" will use
                       * the current value to allow for interruptable keyframes.
                       */
                      whileHover={{ scale: [null, 1.3, 1.2] }}
                      transition={{ duration: 0.2 }}
                    >
                      <Link to={`/articles/${item.label}`} className="link">
                        <div
                          key={item.label}
                          className={`link-rubrique ${item.label}`}
                        >
                          #{item.label}
                        </div>
                      </Link>
                    </motion.div>
                  );
                })
              : null}
          </div>
        </div>
        <div
          className={`padding-left-30 burger-koon-section ${
            isShown ? "shown-burger " : null
          }`}
        >
          <Burger />
        </div>
        {/* <div className="burger-section">
            <Burger />
          </div> */}
      </div>
    </div>
  );
};
export default HeaderKoon;
