import React from "react";
import "./tagSelector.css";

const TagSelector = (props) => {
  const categoryFilter = props.categoryFilter;

  return (
    <div className="central-content-1000 tag-selector">
      <div className="content-column">
        {categoryFilter.map((item) => {
          let test = props.activeTag.find((x) => x.value === item.value);
          return (
            <div key={item.value} className="column-percent-33 padding-top-20">
              <div
                className={`tag-selector-btn-list ${test ? test.value : ""}`}
                id={item.value}
                onClick={props.activeFilter}
              >
                #{item.value}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TagSelector;
