import React from "react";
import "./footer.css";
import Link from "../Link";
import { ROUTES } from "../../app/routing";
import TwitterIcon from "../../res/images/icons/icon-twitter.svg";
import InstagramIcon from "../../res/images/icons/icon-instagram.svg";
import DiscordIcon from "../../res/images/icons/icon-discord.svg";

export const Footer = (props) => {
  const article = props.article ? props.article : null;

  return (
    <div className={`content-footer ${article ? article.tags[0].value : ""}`}>
      <div className="central-content-1200">
        <div className="content-column content-rubrique-footer">
          <div className="column-percent-33 responsive-900d padding-top-30">
            <div className="rubrique-title">À Propos</div>
            <a
              className="text-decoration-none rubrique-element"
              href="mailto:thekoonxyz@gmail.com"
            >
              Nous contacter
            </a>
            <br />
            <Link className={"text-decoration-none"} to={`/articles/NFT`}>
              <div className="rubrique-element">Manifesto</div>
            </Link>
          </div>
          <div className="column-percent-33 responsive-900d padding-top-30">
            <div className="rubrique-title">Rubriques</div>
            <Link className={"text-decoration-none"} to={`/articles/NFT`}>
              <div className="rubrique-element">#NFT</div>
            </Link>
            <div>
              <Link className={"text-decoration-none"} to={`/articles/Defi`}>
                <div className="rubrique-element">#Defi</div>
              </Link>
            </div>
            <Link className={"text-decoration-none"} to={`/articles/Crypto`}>
              <div className="rubrique-element">#Crypto</div>
            </Link>
          </div>
          <div className="column-percent-33 responsive-900d padding-top-30">
            <div className="rubrique-title">Newsletter</div>
            <div className="rubrique-element">S'abonner</div>
          </div>
        </div>
      </div>
      <div className="social-img">
        <a
          href={"https://twitter.com/thekoon_xyz"}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="instagram-icon"
            width="50"
            height="50"
            src={TwitterIcon}
            alt="svg"
          />
        </a>
        <a
          href={"https://www.instagram.com/thekoon.xyz/"}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="instagram-icon"
            width="50"
            height="50"
            src={InstagramIcon}
            alt="svg"
          />
        </a>
        <a
          href={"https://www.instagram.com/thekoon.xyz/"}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="instagram-icon"
            width="50"
            height="50"
            src={DiscordIcon}
            alt="svg"
          />
        </a>
      </div>
      <div className="text-footer">
        © Thekoon 2022 -{" "}
        <Link
          className={"link-footer text-decoration-none"}
          to={ROUTES.MENTION_LEGAL}
        >
          Mentions légales
        </Link>{" "}
        -{" "}
        <Link className={"link-footer text-decoration-none"} to={"/"}>
          Politique de confidentialité
        </Link>{" "}
        -{" "}
        <Link className={"link-footer text-decoration-none"} to={"/"}>
          L'équipe
        </Link>
      </div>
    </div>
  );
};

export default Footer;
