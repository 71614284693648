import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import "./burger.css";

// const Ul = styled.ul`
//   list-style: none;
//   display: flex;
//   flex-flow: row nowrap;
//   li {
//     padding: 18px 10px;
//   }
//   @media (max-width: 768px) {
//     flex-flow: column nowrap;
//     background-color: #6e78ff;
//     position: fixed;
//     transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
//     top: 0;
//     right: 0;
//     height: 100vh;
//     width: 300px;
//     padding-top: 3.5rem;
//     transition: transform 0.3s ease-in-out;
//     li {
//       color: #fff;
//     }
//   }
// `;

const Ul = styled.ul`
  list-style: none;
  display: flex;
  li {
    padding: 18px 10px;
  }
  z-index: 19;
  flex-flow: column nowrap;
  background-color: #6e78ff;
  position: fixed;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  top: 0;
  right: 0;
  height: 100vh;
  width: 300px;
  padding-top: 3.5rem;
  transition: transform 0.3s ease-in-out;
  li {
    color: #fff;
  }
`;

const RightNav = ({ open }) => {
  return (
    <div className="sidebar-content">
      <Ul open={open}>
        <Link className={"text-decoration-none"} to={`/articles/NFT`}>
          <div key={"NFT"} className={`tag-sidebar NFT`}>
            #NFT
          </div>
        </Link>
        <Link className={"text-decoration-none"} to={`/articles/Crypto`}>
          <div key={"Crypto"} className={`tag-sidebar Crypto`}>
            #Crypto
          </div>
        </Link>
        <Link className={"text-decoration-none"} to={`/articles/Defi`}>
          <div key={"Defi"} className={`tag-sidebar Defi`}>
            #Defi
          </div>
        </Link>
      </Ul>
    </div>
  );
};

export default RightNav;
