import React from "react";

import ModelViewer from "@metamask/logo";
import "./logo3d.css";
class Logo3d extends React.Component {
  componentDidMount() {
    this.viewer = ModelViewer({
      // Dictates whether width & height are px or multiplied
      pxNotRatio: true,
      width: this.props.width,
      height: this.props.height,
      followMouse: true, //this.props.followMouse ? this.props.followMouse : false,
    });
    this.el.appendChild(this.viewer.container);
  }

  componentWillUnmount() {
    this.viewer.stopAnimation();
  }

  render() {
    return <div className="logo-img-koon" ref={(el) => (this.el = el)}></div>;
  }
}

export default Logo3d;
