export const ROUTES = {
  OOPS: "/oops", // 404 error
  ARF: "/arf", // 500 error
  REDIRECT: "/r",
  UNAUTHORIZED: "/unauthorized",
  HOME: "/",
  ADMIN: "/admin",
  LOG_ADMIN: "/log-admin",
  ARTICLE: "/article/:id",
  ARTICLES: "/articles/:tag",
  MENTION_LEGAL: "/mention-legal",
  ADMIN_EDIT_ARTICLE: "/admin-article/:id",
};
